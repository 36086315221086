















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FiltersModel } from '../../models/filters/filters.model';
import DateFilter from './DateFilter/DateFilter.vue';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DateType } from '../../models/filters/date-filter.model';
import { NumberType } from '../../models/filters/number-filter.model';
import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

import { DispatchModule, DivisionModule } from '@/store/index';
import { DispatchType } from '../../models/dispatch.model';

import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';
import { createDivisionFilter } from '@/shared/services/division/division.helper';

@Component({
  components: { DateFilter, SelectionFilter }
})
export default class VanFilters extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.search();
  }
  @Watch('division')
  onDivisionChange() {
    this.search();
  }
  dispatchModule = DispatchModule;
  divisionModule = DivisionModule;
  form: any;
  excelData: any;

  selectionTypes = {
    list: [
      {
        value: NumberType.TrailNumber,
        text: this.$i18n.t('mainFilters.trailer')
      },
      {
        value: NumberType.ReferenceNumber,
        text: this.$i18n.t('mainFilters.ref')
      },
      {
        value: NumberType.PONumber,
        text: this.$i18n.t('mainFilters.po')
      },
      { value: NumberType.BookingNumber, text: this.$i18n.t('mainFilters.bk') },
      {
        value: NumberType.BillTo,
        text: this.$i18n.t('mainFilters.billTo')
      },
      {
        value: NumberType.PulloutLocation,
        text: this.$i18n.t('mainFilters.pullOut')
      },
      {
        value: NumberType.DeliveryLocation,
        text: this.$i18n.t('mainFilters.delivery')
      },
      {
        value: NumberType.ReturnLocation,
        text: this.$i18n.t('mainFilters.return')
      },
      {
        value: NumberType.Driver,
        text: this.$i18n.t('mainFilters.driverId')
      }
    ],
    autocompleteList: [
      NumberType.BillTo,
      NumberType.PulloutLocation,
      NumberType.DeliveryLocation,
      NumberType.ReturnLocation,
      NumberType.BookingNumber
    ]
  };

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  typeFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: null,
    field: NumberType.TrailNumber
  });

  constructor() {
    super();
    this.form = new FiltersModel();
  }

  get division() {
    return this.divisionModule.division;
  }

  numberChanged(items) {
    this.typeFilter.search = items.value;
    this.typeFilter.field = items.field;
    console.log(this.$route);
  }

  search() {
    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    DispatchModule.setFilters(this.getFilters());

    DispatchModule.search({
      dispatchType: DispatchType[this.$route.name.toUpperCase()],
      category: this.$route.params.type
    });
  }

  async excel() {
    DispatchModule.setFilters(this.getFilters());

    await DispatchModule.excel({
      dispatchType: DispatchType[this.$route.name.toUpperCase()],
      category: this.$route.params.type
    });

    if (DispatchModule.excelData) {
      window.open(DispatchModule.excelData.url);
    }
  }

  resetFilters() {
    (this.$refs as any).dateFilter.resetFilter();
    (this.$refs as any).selectionFilter.resetFilter();
    this.fromFilter = new FilterInput({
      condition: FilterConditions.GreaterOrEqual,
      search: moment()
        .subtract(12, 'days')
        .format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.toFilter = new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment().format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.typeFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: NumberType.TrailNumber
    });
    DispatchModule.setFilters(this.getFilters());
  }

  private getFilters(): string {
    return TransformFiltersToJson([
      this.toFilter,
      this.fromFilter,
      this.typeFilter,
      createDivisionFilter()
    ]);
  }
}
