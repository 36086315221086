































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { VanTableTopTypes, VanTableTypes } from './models/van-table';
import { TopTableHeaders } from '../models/cellRender.model';
import VanFilters from './VanFilters/VanFilters.vue';

import { DispatchModule } from '@/store';
import { DispatchType } from '../models/dispatch.model';

import { VanType, VanModels, VanLocationBuilder } from './models/van-models';

import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';

import Loading from '@/shared/components/Loading.vue';

import { JobPayments } from '@/shared/models/driverIdModal.model';
import DriverIdModal from '@/shared/components/modal/DriverIdModal.vue';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { LocationType } from '@/shared/models/woSchedule.model';
import { isClickedOnInput } from '@/shared/models/wo.model';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import { ToastHelper } from '@/utils/toast.util';
import { getRowSchedules } from '../utils/get-row-schedules';
import {
  getScheduleLocationErrors,
  getScheduleOrderErrors
} from '@/shared/services/wo/schedule-validation';
import { PermissionsService } from '@/shared/services/permission/permission.service';
import { deepClone } from '@/utils/utils';

Component.registerHooks(['validations']);

@Component({
  components: {
    VanFilters,
    WoBaseModal,
    Loading,
    DriverIdModal,
    DriverInputWrapper,
    TmsTable
  }
})
export default class Van extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.categoryTableHeaders = deepClone(
      VanTableTopTypes[this.$route.params.type]
    );
    this.columnHeaders = deepClone(VanTableTypes[this.$route.params.type]);
    this.classes = generateRouterClasses(this.$route);
  }

  tabIndex = 0;
  classes: string[] = [];
  woModalId = 'wo-base-modal';

  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  activeItem = null;

  jobPayments: JobPayments = null;

  LocationType = LocationType;

  dispatchModule = DispatchModule;
  categoryTableHeaders: TopTableHeaders[] = [];
  columnHeaders = [];
  selectedWo = {};

  disabled = false;

  // form: any = new VanModels[this.category]();

  options = {
    dropLive: [
      { value: 'SELECT', text: 'Select' },
      { value: 'DROP', text: 'Drop' },
      { value: 'LIVE', text: 'Live' }
    ]
  };

  constructor() {
    super();
  }

  get form() {
    return new VanModels[this.category]();
  }

  get editableData() {
    return this.dispatchModule.dispatches;
  }

  get category() {
    return this.$route.params.type;
  }

  get isLastPage(): boolean {
    return !this.dispatchModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'VAN',
      'View'
    );
  }

  rowClass(item, type) {
    if (!item || type !== 'row') return;

    if ((item.cfFile && item.cfsFile) || item.cfFile) {
      return 'body__tr light-red-row';
    } else if (item.cfsFile) {
      return 'body__tr green-row';
    }
  }

  mergeObjects(currentValue) {
    const model = new VanModels[this.category]();

    for (const key in model) {
      model[key] = currentValue[key];
    }

    model.lastFreeDay =
      model.lastFreeDay === 'Invalid date' ? null : model.lastFreeDay;

    return model;
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'save':
        this.checkJobPayments(data);
        break;
      case 'complete':
        this.completeOrder(data.orderNumber);
        break;
      case 'chassisPool':
      case 'dropLive':
        break;
      default:
        this.openDetailModal(data);
    }
  }

  async checkJobPayments(item) {
    this.activeItem = item;

    const editedSchedules = getRowSchedules(item);

    const datesOrderErrors = getScheduleOrderErrors(editedSchedules);
    if (datesOrderErrors.length) {
      ToastHelper.show('Error', datesOrderErrors[0], 5000, 'danger');
      return;
    }

    this.save({
      jobPayments: item.jobPayments,
      schedules: editedSchedules
    });
  }

  save({ jobPayments, schedules }) {
    this.$bvModal.hide('DriverIdModal');
    const data: any = {};

    if (this.activeItem.avaDsc) {
      data.avaDsc = this.activeItem.avaDsc;
    }

    if (this.activeItem.avaOth) {
      data.avaOth = this.activeItem.avaOth;
    }

    if (this.activeItem.dropLive && this.$route.params.type !== 'return') {
      data.dropLive = this.activeItem.dropLive;
    }

    DispatchModule.save({
      dispatchType: DispatchType[this.$route.name.toUpperCase()],
      category: this.$route.params.type,
      orderNumber: this.activeItem.orderNumber,
      data: {
        ...data,
        jobPayments,
        schedules,
        remarks: this.activeItem.remarks
      }
    });
  }

  completeOrder(orderNumber) {
    WorkOrderService.completeOrder(
      DispatchType.VAN,
      orderNumber,
      'dispatch',
      false
    );
  }

  openDetailModal(selectedData, index = 0, event?) {
    if (event && isClickedOnInput(event)) {
      return;
    }

    this.selectedWo = selectedData;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openDetailModal(event.item, event.option.index);
  }

  async search() {
    try {
      await DispatchModule.search({
        dispatchType: DispatchType[this.$route.name.substr(0, 3).toUpperCase()],
        category: this.$route.params.type
      });
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }
}
