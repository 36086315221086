


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { DateType } from '../../../models/filters/date-filter.model';
import { FilterInput } from '@/shared/services/filter/filter.service';
import {
  convertAPIToDateFormat,
  convertDateFieldToAPIFormatWithoutTime
} from '@/utils/date.util';

import moment from 'moment-timezone';

@Component({
  components: { Fragment }
})
export default class DateFilter extends Vue {
  @Prop() fromFilter: FilterInput<string>;
  @Prop() toFilter: FilterInput<string>;

  filterField = DateType.CreatedDate;
  to = null;
  from = null;

  @Watch('fromFilter')
  onFromFilterChange() {
    this.from = convertAPIToDateFormat(this.fromFilter.search);
  }
  @Watch('toFilter')
  onToFilterChange() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
  }

  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;

  maxDate = null;

  dateTypes = [
    {
      value: DateType.CreatedDate,
      text: this.$i18n.t('mainFilters.createdDate')
    },
    { value: DateType.PulloutDate, text: this.$i18n.t('mainFilters.pullOut') },
    {
      value: DateType.DeliveryDate,
      text: this.$i18n.t('mainFilters.delivery')
    },
    { value: DateType.MTYPU, text: this.$i18n.t('mainFilters.pickUp') },
    { value: DateType.ReturnDate, text: this.$i18n.t('mainFilters.return') }
  ];

  created() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
    this.from = convertAPIToDateFormat(this.fromFilter.search);
    this.filterField === DateType.CreatedDate
      ? (this.maxDate = moment().format())
      : (this.maxDate = null);
  }

  fieldChange() {
    this.filterField === DateType.CreatedDate
      ? (this.maxDate = moment().format())
      : (this.maxDate = null);
    this.toFilter.field = this.filterField;
    this.fromFilter.field = this.filterField;
  }

  resetFilter() {
    this.filterField = DateType.CreatedDate;
  }
}
